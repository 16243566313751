import React from "react";

import { useTranslation } from "react-i18next";

import classes from "./LanguageSwitch.module.scss";

const LanguageSwitchList = ({ currentLang, changeLang }) => {
  const { t } = useTranslation();

  const LANG_LIST = ["en", "pl"];

  return (
    <div className={`${classes.lang__switch_list}`}>
      {LANG_LIST.map((lang) => (
        <div
          className={`
            ${classes.lang__switch_list_item}
            ${lang===currentLang ? classes.lang__switch_list_item_active : ""}
            p-0_5  
          `}
          key={lang}
          onClick={() => changeLang(lang)}
        >
          {t(`lang.${lang}`)}
        </div>
      ))}
    </div>
  );
};

export default LanguageSwitchList;
