import React, { useState, useEffect } from "react";
import i18n from "../../../i18n";

import LanguageSwitchBtn from "./LanguageSwitchBtn";
import LanguageSwitchList from "./LanguageSwitchList";

import classes from "./LanguageSwitch.module.scss";

const LanguageSwitch = ({ lang }) => {
  const [currentLang, setCurrentLang] = useState(lang);
  const [langSelectVisible, setLangSelectVisible] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(currentLang);
  }, [currentLang]);

  const toggleLangList = () => {
    setLangSelectVisible(!langSelectVisible);
  };

  const changeLang = (languageToChange) => {
    toggleLangList();
    setCurrentLang(languageToChange);
  };

  return (
    <div className={classes.lang__switch_container}>
      <LanguageSwitchBtn
        currentLang={currentLang}
        toggleLangList={toggleLangList}
      />

      {langSelectVisible && (
        <LanguageSwitchList currentLang={currentLang} changeLang={changeLang} />
      )}
    </div>
  );
};

export default LanguageSwitch;
