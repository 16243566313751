import React, { useState } from "react";
import HeaderContext from "./header-context";

const HeaderContextProvider = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  
  const onScrollShow = () => {
    setIsVisible(false)
  }

  const onScrollHide = () => {
    setIsVisible(true)
  }

  const contextValue = {
    isVisible: isVisible,
    onScrollShow: onScrollShow,
    onScrollHide: onScrollHide,
  };

  return (
    <HeaderContext.Provider value={contextValue}>
      {props.children}
    </HeaderContext.Provider>
  );
};

export default HeaderContextProvider;
