import React from "react";

import { useTranslation } from "react-i18next";

import classes from "./LanguageSwitch.module.scss";

const LanguageSwitchBtn = ({ currentLang, toggleLangList }) => {
  const { t } = useTranslation();

  return (
    <div
      className={`${classes.lang__switch} flex justify-content-end`}
      onClick={toggleLangList}
    >
      <i
        className={`icon large globe`}
      ></i>
      <div className={`${classes.lang__switch_label}`}>
        {t(`lang.${currentLang}`)}
      </div>
    </div>
  );
};

export default LanguageSwitchBtn;
