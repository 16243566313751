import React, { useEffect } from "react";
import i18n from "./i18n";

import Header from "./components/Header/Header"

import "./App.scss";

const HeroHeader = React.lazy(() => import('./components/HeroHeader/HeroHeader'));
const Articles = React.lazy(() => import('./components/Articles/Articles'));
const HowWeDoIt = React.lazy(() => import('./components/HowWeDoIt/HowWeDoIt'));
const Process = React.lazy(() => import('./components/Process/Process'));

const Footer = React.lazy(() => import('./components/Footer/Footer'));
const CookieContainer = React.lazy(() => import('./components/CookieContainer/CookieContainer'));

function App({ lang }) {
  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  return (
    <React.Fragment>
      <Header lang={lang} />
      <main>
        <HeroHeader />
        <Articles />
        <HowWeDoIt />
        <Process />
      </main>
      <Footer />
      <CookieContainer />
    </React.Fragment>
  );
}

export default App;
