import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import HeaderContext from "../../store/header/header-context";

import HeaderContainer from "../UI/HeaderContainer/HeaderContainer";
import LanguageSwitch from "../UI/LanguageSwitch/LanguageSwitch";

import classes from "./Header.module.scss";

const PlugsunLogo = React.lazy(() => import("../../assets/icons/PlugsunLogo"));

function Header({ lang }) {
  const { t } = useTranslation();
  const headerCtx = useContext(HeaderContext);

  return (
    <React.Fragment>
      <header
        className={`${classes.header} ${
          headerCtx.isVisible ? classes.header_full : classes.header_transparent
        }`}
      >
        <HeaderContainer>
          <div className={`justify-content-end ${classes.header_nav_mobile}`}>
            <a
              id="plugSun_logo"
              href="/"
              className={`${classes.logo} ${
                headerCtx.isVisible ? "show" : "remove"
              }`}
            >
              <PlugsunLogo size="size-log-small" />
            </a>
            <LanguageSwitch lang={lang} />
          </div>

          <div className={`${classes.header_nav}`}>
            <div>
              <a
                id="plugSun_logo"
                href="/"
                className={`${classes.logo} ${
                  headerCtx.isVisible ? "show" : "remove"
                }`}
              >
                <PlugsunLogo size="size-xl" />
              </a>
            </div>

            <nav
              className={`flex justify-content-space-between ${classes.header_navigation}`}
            >
              <div>
                <a
                  href="#articles"
                  className={`${
                    headerCtx.isVisible ? "primary" : classes.dark
                  } ${classes.header__links}`}
                >
                  {t("sections.vision")}
                </a>
                <a
                  href="#howWeDoIt"
                  className={`${
                    headerCtx.isVisible ? "primary" : classes.dark
                  } ${classes.header__links}`}
                >
                  {t("sections.howWeDoIt")}
                </a>
                <a
                  href="#process"
                  className={`${
                    headerCtx.isVisible ? "primary" : classes.dark
                  } ${classes.header__links}`}
                >
                  {t("sections.process")}
                </a>
              </div>

              <LanguageSwitch lang={lang} />
            </nav>
          </div>
        </HeaderContainer>
      </header>
    </React.Fragment>
  );
}

export default Header;
