import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import HeaderContextProvider from "./store/header/HeaderProvider";

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<div>Loading... </div>}>
      <HeaderContextProvider>
        <App lang={navigator.language} />
      </HeaderContextProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

